// src/views/admin/default/components/Chat.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Input,
  Button,
  Flex,
  Text,
  VStack,
  Icon,
  Avatar,
  useColorModeValue,
  useBreakpointValue,
  InputGroup,
  InputLeftElement,
  Badge,
  useToast,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import { MdSend } from 'react-icons/md';
import { SearchIcon, ArrowBackIcon } from '@chakra-ui/icons';
import axios from '../../../../axiosInstance';
import { io } from 'socket.io-client';
import { jwtDecode } from 'jwt-decode'; // Importação nomeada

// Importação direta do arquivo de áudio
import notificationSoundFile from '../../../../assets/audios/notifymoderno.mp3';

const API_BASE_URL = 'https://etc.querubimcompany.com';
const SOCKET_SERVER_URL = 'https://etc.querubimcompany.com';

const Chat = ({ questions = [], answers = {} }) => {
  const [chats, setChats] = useState([]);
  const [filteredChats, setFilteredChats] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [unreadChats, setUnreadChats] = useState({});
  const [userRole, setUserRole] = useState(undefined);
  const [isSending, setIsSending] = useState(false);
  const [loadingChats, setLoadingChats] = useState(true);
  const [isConnected, setIsConnected] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const messagesContainerRef = useRef(null);
  const socketRef = useRef(null);
  const activeChatIdRef = useRef(activeChatId);
  const currentChatIdRef = useRef(null);
  const toast = useToast();

  // Inicializar o som de notificação com o arquivo importado
  const notificationSound = useRef(new Audio(notificationSoundFile));

  const messagesRef = useRef(messages);
  const chatsRef = useRef(chats);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    chatsRef.current = chats;
  }, [chats]);

  // Definições de cores
  const scrollbarWidth = '8px';
  const scrollbarTrackBg = useColorModeValue('gray.100', 'gray.700');
  const scrollbarThumbBg = useColorModeValue('gray.400', 'gray.600');
  const scrollbarThumbHoverBg = useColorModeValue('gray.500', 'gray.700');
  const scrollbarColor = `${scrollbarThumbBg} ${scrollbarTrackBg}`;

  const chatBg = useColorModeValue('white', 'gray.800');
  const headerBg = useColorModeValue('gray.100', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'white');
  const messageBoxBg = useColorModeValue('white', 'gray.700');
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  const sentMessageBg = useColorModeValue('blue.500', 'blue.300');
  const receivedMessageBg = useColorModeValue('gray.200', 'gray.600');
  const sentMessageTextColor = useColorModeValue('white', 'white');
  const receivedMessageTextColor = useColorModeValue('gray.800', 'white');

  // Decodificação do token
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log('Token Decodificado:', decodedToken);
        const normalizedRole = decodedToken.role?.toLowerCase();
        setUserRole(normalizedRole);
        console.log('User Role:', normalizedRole);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        setUserRole('unknown');
      }
    } else {
      console.warn('Token não encontrado no localStorage');
      setUserRole('guest');
    }
  }, []);

  // Função para buscar as conversas do usuário
  const fetchConversations = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/dashboard/user-chats`);
      // Ordenar conversas por última atividade
      const sortedChats = response.data.sort(
        (a, b) => new Date(b.lastActive) - new Date(a.lastActive)
      );
      setChats(sortedChats);
    } catch (error) {
      console.error('Erro ao buscar conversas:', error);
      toast({
        title: 'Erro ao buscar conversas',
        description: 'Tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoadingChats(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  // Função para buscar mensagens de um chat específico
  const fetchMessages = useCallback(
    async (chatId) => {
      if (!chatId) {
        console.warn('ID do chat inválido:', chatId);
        return;
      }

      try {
        setMessages([]);
        const response = await axios.get(`${API_BASE_URL}/dashboard/chat/${chatId}/messages`);
        setMessages(response.data);
      } catch (error) {
        console.error('Erro ao buscar mensagens:', error);
        toast({
          title: 'Erro ao buscar mensagens',
          description: 'Tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }
    },
    [toast]
  );

  // Função para entrar na sala de chat via Socket.io
  const joinChatRoom = useCallback(
    (chatId) => {
      if (!socketRef.current) {
        console.warn('WebSocket não inicializado');
        return;
      }

      const chatIdNumber = parseInt(chatId, 10);
      if (isNaN(chatIdNumber)) {
        console.error('Invalid chatId:', chatId);
        return;
      }

      // Se já estiver em uma sala diferente, saia dela
      if (currentChatIdRef.current && currentChatIdRef.current !== chatId) {
        console.log(`Saindo da sala chat_${currentChatIdRef.current}`);
        socketRef.current.emit(
          'leaveChat',
          { chatId: currentChatIdRef.current },
          (response) => {
            if (response.status === 'ok') {
              console.log(`Saiu com sucesso da sala: chat_${currentChatIdRef.current}`);
            } else {
              console.error(`Falha ao sair da sala: chat_${currentChatIdRef.current}`);
            }
          }
        );
      }

      console.log('Enviando joinChat para o servidor com chatId:', chatId);

      socketRef.current.emit('joinChat', { chatId }, (response) => {
        console.log('Resposta do servidor para joinChat:', response);
        if (response.status === 'ok') {
          console.log(`Entrou com sucesso na sala de chat: chat_${chatId}`);
          currentChatIdRef.current = chatId; // Atualizar a referência para a nova sala
        } else {
          console.error(`Falha ao entrar na sala de chat: chat_${chatId}`);
          toast({
            title: 'Erro ao entrar na sala do chat',
            description: 'Tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        }
      });
    },
    [toast]
  );

  // Hook para buscar mensagens e entrar na sala quando um chat é ativado
  useEffect(() => {
    if (activeChatId && !isNaN(parseInt(activeChatId, 10))) {
      fetchMessages(activeChatId);
      joinChatRoom(activeChatId);
    }
  }, [activeChatId, fetchMessages, joinChatRoom]);

  // Atualizar a referência do chat ativo
  useEffect(() => {
    activeChatIdRef.current = activeChatId;
  }, [activeChatId]);

  // Função para inicializar o WebSocket
  const initializeWebSocket = useCallback(() => {
    if (socketRef.current) return; // Evita inicialização múltipla

    console.log('Inicializando WebSocket...');
    socketRef.current = io(SOCKET_SERVER_URL, {
      transports: ['websocket'],
      secure: true,
      auth: {
        token: localStorage.getItem('authToken'),
      },
      reconnectionAttempts: 10,
      reconnectionDelay: 2000,
    });

    socketRef.current.on('connect', () => {
      console.log('Conectado ao servidor Socket.io');
      setIsConnected(true);
    });

    socketRef.current.on('disconnect', () => {
      console.log('Desconectado do WebSocket');
      setIsConnected(false);
      currentChatIdRef.current = null; // Resetar a referência da sala atual
    });

    socketRef.current.on('connect_error', (error) => {
      console.error('Erro ao conectar ao WebSocket:', error);
      setIsConnected(false);
      toast({
        title: 'Erro ao conectar ao WebSocket',
        description: 'Tentando reconectar...',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    });

    // Remover listeners antigos antes de adicionar novos
    socketRef.current.off('newMessage');
    socketRef.current.off('newChat'); // Remover 'newChat' para evitar duplicações

    // Listener para o evento 'newMessage'
    socketRef.current.on('newMessage', (message) => {
      console.log('Nova mensagem recebida:', message);
      let incomingChatId = null;

      if (message.chatId) {
        incomingChatId = message.chatId.toString();
      } else if (message.chat && message.chat.id) {
        incomingChatId = message.chat.id.toString();
      } else {
        console.error('Mensagem recebida sem chatId:', message);
        return; // Ignorar mensagens sem chatId definido
      }

      console.log(`Mensagem recebida para chatId: ${incomingChatId}`);

      const messageExists = messagesRef.current.some((msg) => msg.id === message.id);
      if (messageExists) {
        console.log('Mensagem duplicada detectada. Ignorando...');
        return;
      }

      const isOwnMessage = message.sender === userRole;

      if (!isOwnMessage) {
        // Reproduzir o som de notificação para mensagens recebidas de outros usuários
        if (notificationSound.current) {
          // Reiniciar o áudio caso já esteja tocando
          notificationSound.current.pause();
          notificationSound.current.currentTime = 0;

          notificationSound.current.play()
            .then(() => {
              console.log('Som de notificação reproduzido com sucesso');
            })
            .catch((error) => {
              console.error('Erro ao reproduzir som de notificação:', error);
            });
        } else {
          console.warn(
            'notificationSound.current está null. Não foi possível reproduzir o som.'
          );
        }
      }

      if (incomingChatId === activeChatIdRef.current) {
        setMessages((prevMessages) => [...prevMessages, message]);
      } else {
        setUnreadChats((prev) => ({
          ...prev,
          [incomingChatId]: {
            count: (prev[incomingChatId]?.count || 0) + 1,
            lastMessageTimestamp: message.timestamp,
          },
        }));
      }

      // Atualizar a lista de conversas com a última mensagem
      setChats((prevChats) =>
        prevChats
          .map((chat) =>
            chat.id.toString() === incomingChatId
              ? { ...chat, lastMessage: message.message, lastActive: message.timestamp }
              : chat
          )
          .sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive)) // Ordenar conversas por última atividade
      );
    });

    // Listener para o evento 'newChat'
    socketRef.current.on('newChat', (newChat) => {
      console.log('Novo chat recebido via WebSocket:', newChat);
      setChats((prevChats) => [newChat, ...prevChats]);
    });
  }, [toast, userRole]);

  // Hook para inicializar o WebSocket na montagem do componente
  useEffect(() => {
    initializeWebSocket();

    // Listener para desbloquear áudio após interação do usuário
    const handleUserInteraction = () => {
      if (notificationSound.current) {
        notificationSound.current.play()
          .then(() => {
            console.log('Som desbloqueado após interação do usuário');
            // Pausar imediatamente após desbloquear
            notificationSound.current.pause();
            notificationSound.current.currentTime = 0;
            // Remova o listener após desbloquear
            window.removeEventListener('click', handleUserInteraction);
          })
          .catch((error) => {
            console.error('Erro ao desbloquear som após interação:', error);
          });
      }
    };

    window.addEventListener('click', handleUserInteraction);

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
        console.log('Desconectado do WebSocket');
      }

      // Pausar e limpar o som de notificação quando o componente for desmontado
      if (notificationSound.current) {
        notificationSound.current.pause();
        notificationSound.current = null;
      }

      window.removeEventListener('click', handleUserInteraction);
    };
  }, [initializeWebSocket]);

  // Scroll automático para a última mensagem
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  // Lógica de pesquisa
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredChats(chats);
    } else {
      const filtered = chats.filter((chat) =>
        chat.chatType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        chat.lastMessage?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredChats(filtered);
    }
  }, [searchTerm, chats]);

  // Função para enviar uma mensagem (via Axios apenas)
  const handleSendMessage = useCallback(async () => {
    if (!socketRef.current || !isConnected) {
      console.error('Tentativa de envio de mensagem sem conexão WebSocket');
      toast({
        title: 'WebSocket não conectado',
        description: 'Tentando reconectar...',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      return;
    }

    if (message.trim() === '' || !activeChatId) return;

    const chatIdNumber = parseInt(activeChatId, 10);
    if (isNaN(chatIdNumber)) {
      console.error('chatId is not a valid number:', activeChatId);
      return;
    }

    const newMessage = {
      chatId: chatIdNumber,
      message: message.trim(),
      mediaUrl: null,
    };

    try {
      setIsSending(true);
      console.log('Enviando mensagem como:', userRole);
      console.log('Mensagem sendo enviada para chatId:', newMessage.chatId);
      const response = await axios.post(`${API_BASE_URL}/dashboard/chat/message`, newMessage, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 || response.status === 201) {
        // Não emita via Socket.io para evitar duplicação
        // A mensagem será recebida via 'newMessage' emitido pelo backend
        console.log('Mensagem enviada com sucesso via Axios:', response.data);
      } else {
        throw new Error('Falha no envio da mensagem');
      }
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      toast({
        title: 'Erro ao enviar mensagem',
        description: 'Tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setIsSending(false);
      setMessage('');
    }
  }, [isConnected, message, activeChatId, userRole, toast]);

  // Função para detectar o pressionamento da tecla Enter
  const handleTyping = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  // Função para selecionar um chat
  const handleChatClick = useCallback(
    (chatId) => {
      console.log(`Selecionando chat com ID: ${chatId}`);
      setActiveChatId(chatId.toString());

      // Resetar contagem de mensagens não lidas para este chat
      setUnreadChats((prev) => ({
        ...prev,
        [chatId.toString()]: {
          count: 0,
          lastMessageTimestamp: prev[chatId.toString()]?.lastMessageTimestamp || null,
        },
      }));
    },
    []
  );

  // Condicional de renderização do spinner
  if (userRole === undefined) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex
      height="calc(85vh - 80px)" // Mantém a altura original
      p={{ base: '4px', md: '16px' }} // Reduz o padding no mobile para aumentar o espaço horizontal
      gap={{ base: '4px', md: '16px' }} // Reduz o gap no mobile
      mt="80px"
      direction={{ base: 'column', md: 'row' }} // Mantém layout responsivo
      overflow="hidden" // Evita overflow indesejado
      align={{ base: 'stretch', md: 'stretch' }} // Alinha os itens para esticar em ambas as direções
    >
      {/* Lista de Conversas */}
      {(!isMobile || !activeChatId) && (
        <Box
          width={{
            base: '100%', // Mantém 100% da largura no mobile
            md: '350px', // Mantém 350px no desktop
          }}
          height={{
            base: '100%', // Altura fixa no mobile
            md: '700px', // Altura automática no desktop
          }}
          bg="white"
          p="4"
          borderRadius="12px"
          shadow="lg"
          borderWidth="1px"
          borderColor="gray.200"
          overflowY="auto"
          sx={{
            '&::-webkit-scrollbar': {
              width: scrollbarWidth,
            },
            '&::-webkit-scrollbar-track': {
              background: scrollbarTrackBg,
              borderRadius: '12px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: scrollbarThumbBg,
              borderRadius: '12px',
              transition: 'background 0.3s',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: scrollbarThumbHoverBg,
            },
            scrollbarWidth: 'thin',
            scrollbarColor: scrollbarColor,
          }}
        >
          <Text fontWeight="bold" mb="4" fontSize="xl" color={textColor}>
            Conversas Recentes
          </Text>
          {loadingChats ? (
            <Flex justify="center" align="center" height="100%">
              <Spinner color="blue.500" size="lg" />
            </Flex>
          ) : chats.length > 0 ? (
            <>
              <InputGroup mb="4">
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
                />
                <Input
                  variant="filled"
                  fontSize="sm"
                  bg={inputBg}
                  color={inputText}
                  placeholder="Pesquisar conversas..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <VStack spacing="4" align="stretch">
                {filteredChats.length > 0 ? (
                  filteredChats.map((chat) => (
                    <Flex
                      key={chat.id}
                      p="3"
                      bg={chat.id.toString() === activeChatId ? 'gray.200' : 'white'}
                      borderRadius="md"
                      align="center"
                      onClick={() => handleChatClick(chat.id)}
                      cursor="pointer"
                      _hover={{ bg: 'gray.100' }}
                      position="relative"
                    >
                      <Avatar name={`Chat ${chat.id}`} size="md" mr="3" />
                      <Box flex="1" maxW="200px">
                        <Text fontWeight="bold" fontSize="sm" color={textColor}>
                          {chat.chatType}
                        </Text>
                        <Text fontSize="xs" color="gray.500" isTruncated>
                          {chat.lastMessage || 'Sem mensagens'}
                        </Text>
                      </Box>
                      {/* Bolinha de Notificação */}
                      {unreadChats[chat.id.toString()]?.count > 0 && (
                        <Box
                          position="absolute"
                          top="10px"
                          right="10px"
                          width="8px"
                          height="8px"
                          bg="red.500"
                          borderRadius="50%"
                        />
                      )}
                      {/* Badge com Contador de Mensagens Não Lidas */}
                      {unreadChats[chat.id.toString()]?.count > 0 && (
                        <Badge colorScheme="red" position="absolute" top="8px" right="25px">
                          {unreadChats[chat.id.toString()].count}
                        </Badge>
                      )}
                    </Flex>
                  ))
                ) : (
                  <Text fontSize="sm" color="gray.500">
                    Nenhuma conversa encontrada.
                  </Text>
                )}
              </VStack>
            </>
          ) : (
            // Placeholder quando não há conversas
            <Flex justify="center" align="center" height="100%">
              <Text fontSize="sm" color="gray.500">
                Você ainda não tem conversas.
              </Text>
            </Flex>
          )}
        </Box>
      )}

      {/* Chat Ativo */}
      <Flex
        flex="1"
        direction="column"
        bg={chatBg}
        borderRadius="12px"
        shadow="lg"
        width={{
          base: '100%', // Ocupar toda a largura no mobile quando ativo
          md: 'calc(100% - 350px)', // Ajusta conforme a lista de conversas no desktop
        }}
        display={!activeChatId && isMobile ? 'none' : 'flex'}
        overflow="hidden" // Garante que o conteúdo não ultrapasse os limites
      >
        {activeChatId ? (
          <>
            {/* Cabeçalho do Chat */}
            <Box
              width="100%"
              bg={headerBg}
              p="16px"
              borderTopRadius="12px"
              display="flex"
              alignItems="center"
            >
              {isMobile && (
                <IconButton
                  aria-label="Voltar para conversas"
                  icon={<ArrowBackIcon />}
                  mr="3"
                  onClick={() => setActiveChatId(null)}
                  variant="ghost"
                  size="md"
                />
              )}
              <Avatar name={`Chat ${activeChatId}`} size="md" mr="3" />
              <Text fontSize="lg" fontWeight="bold" color={textColor}>
                Chat {activeChatId}
              </Text>
            </Box>

            {/* Área de Mensagens */}
            <Box
              flex="1"
              overflowY="auto"
              p="4"
              bg={messageBoxBg}
              ref={messagesContainerRef}
              sx={{
                '&::-webkit-scrollbar': {
                  width: scrollbarWidth,
                },
                '&::-webkit-scrollbar-track': {
                  background: scrollbarTrackBg,
                  borderRadius: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: scrollbarThumbBg,
                  borderRadius: '12px',
                  transition: 'background 0.3s',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: scrollbarThumbHoverBg,
                },
                scrollbarWidth: 'thin',
                scrollbarColor: scrollbarColor,
              }}
            >
              <VStack spacing="4" align="stretch">
                {messages.length > 0 ? (
                  messages.map((msg) => {
                    const isOwnMessage = msg.sender === userRole;
                    const messageBg = isOwnMessage ? sentMessageBg : receivedMessageBg;
                    const messageTextColor = isOwnMessage
                      ? sentMessageTextColor
                      : receivedMessageTextColor;
                    const messageAlign = isOwnMessage ? 'flex-end' : 'flex-start';
                    const textAlign = isOwnMessage ? 'right' : 'left';
                    const timestampColor = isOwnMessage ? 'white' : 'gray.500';

                    console.log(
                      `Renderizando mensagem: sender=${msg.sender}, isOwnMessage=${isOwnMessage}`
                    );

                    return (
                      <Flex
                        key={msg.id || msg.timestamp}
                        justify={messageAlign}
                        align="flex-end"
                        mb="2"
                      >
                        <Box
                          p="12px"
                          bg={messageBg}
                          color={messageTextColor}
                          borderRadius="15px"
                          maxWidth="70%"
                          wordBreak="break-word"
                        >
                          <Text fontSize="md">{msg.message}</Text>
                          <Text
                            fontSize="xs"
                            color={timestampColor}
                            mt="1"
                            textAlign={textAlign}
                          >
                            {new Date(msg.timestamp).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })
                ) : (
                  <Flex justify="center" align="center" height="100%">
                    <Text fontSize="sm" color="gray.500">
                      Sem mensagens neste chat.
                    </Text>
                  </Flex>
                )}
              </VStack>
            </Box>

            {/* Área de Envio de Mensagens */}
            <Flex align="center" p="4" bg={messageBoxBg} borderBottomRadius="12px">
              <Input
                placeholder="Digite sua mensagem..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleTyping}
                mr="2"
                borderRadius="24px"
                bg="gray.200"
                disabled={isSending}
              />
              <Button
                onClick={handleSendMessage}
                bg="gray.500"
                color="white"
                borderRadius="full"
                _hover={{ bg: 'gray.600' }}
                isLoading={isSending}
              >
                <Icon as={MdSend} />
              </Button>
            </Flex>
          </>
        ) : (
          // Placeholder quando nenhum chat está ativo
          <Flex justify="center" align="center" flex="1">
            <Text fontSize="md" color="gray.500">
              Selecione uma conversa para começar a conversar.
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Chat;
